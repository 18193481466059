import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 972px;
  width: 100%;
`;

const Title = styled.h1`
  font-weight: 200;
  font-size: 56px;
  color: #111111;
`;

const Subtitle = styled.h2`
  font-weight: 200;
  font-size: 42px;
  color: #111111;
`;

const Paragraph = styled.p`
  font-weight: 200;
  font-size: 18px;
  color: #111111;
`;

const Ul = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  color: #111;
`;

const Li = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  font-size: 20.8px;
  font-weight: 200;
`;

function Hero() {
  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          <Content>
            <Title>Thank You!</Title>
            <Subtitle>PDF Writer has been successfully installed.</Subtitle>
            <Paragraph>
              You might wish to consider the following information in order to
              get the most out of PDF Writer :
            </Paragraph>
            <Ul>
              <Li>
                Please be sure to enter your license to ensure that you are
                using the full version of PDF Writer, not just a trial.
              </Li>
              <Li>
                For information on common tasks, please consult our{" "}
                <a href="https://pdfwriter.com/">knowledge base articles</a> .
              </Li>
              <Li>
                If you have further inquiries, please do not hesitate to submit
                a <a href="https://pdfwriter.com/">support request here</a>
              </Li>
            </Ul>
          </Content>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}
export default Hero;
