import React from "react";
import Seo from "../components/shared/seo";
import Hero from "../components/installedPage/hero";
import Layout from "../components/shared/layout";

function Installed() {
  return (
    <Layout>
      <Seo title="Installed Page" />
      <Hero />
    </Layout>
  );
}

export default Installed;
